import axios from "axios";
const originalUrl = process.env.REACT_APP_ORIGINAL_API_URL;
const testingUrl = process.env.REACT_APP_TESTING_API_URL;

export const user = {
  checkPass: () => axios.get(`${testingUrl}/user/pass_check`),
};

export const place = {
  postLike: hid =>
    axios.post(
      `${originalUrl}/hospitals/web/${hid}/like`,
      {},
      {
        hideBearer: true,
      }
    ),
  deleteLike: hid =>
    axios.delete(`${originalUrl}/hospitals/web/${hid}/like`, {
      hideBearer: true,
    }),
};
