import React, { useState, useEffect, useCallback } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import useHospital from "../../hooks/useHospital";
import Layout from "../../component/common/layout";
import YoutubeList from "../../component/hospital/youtubeList";
import YoutubeDetail from "./YoutubeDetail";
import { useEventListener } from "../../utils/use_event_listener";

const Youtube = ({ match }) => {
  const hid = match.params.hid;
  const hospital = useHospital(hid).data;
  const [data, setData] = useState(null);
  const [pagination, setPagination] = useState({ current_page: 0, total_page: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const getYoutubeList = useCallback(id => {
    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/hospitals/${id}/youtubes`)
      .then(function (response) {
        setData(response.data.data);
        setPagination(response.data.meta.pagination);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const getMoreList = useCallback(() => {
    if (!pagination?.links.next) return;
    if (isLoading) return;

    setIsLoading(true);
    axios
      .get(pagination.links.next)
      .then(response => {
        setData([...data, ...response.data.data]);
        setPagination(response.data.meta.pagination);
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false));
  }, [data, pagination, isLoading]);

  const handleScroll = e => {
    if (window.location.pathname.includes("detail")) return;
    let offset = (document.body.scrollHeight - document.body.clientHeight) * 0.8;
    if (offset < window.scrollY && !isLoading) {
      getMoreList();
    }
  };

  useEffect(() => {
    if (!hospital) return;
    window.scroll(0, 0);
    getYoutubeList(hospital.id);
  }, [hospital, getYoutubeList]);

  useEventListener("scroll", handleScroll, window);

  if (!data || !hospital) return false;
  return (
    <>
      <Route
        exact
        path={["/hospital/:hid", "/hospital/:hid/youtube"]}
        render={() => (
          <Layout title={hospital.name} containerClassName="youtube" buttons={["back"]}>
            <YoutubeList data={data} />
          </Layout>
        )}
      />
      <Route exact path="/hospital/:hid/youtube/detail/:id" render={() => <YoutubeDetail data={data} />} />
    </>
  );
};

export default Youtube;
