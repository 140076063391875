import React from "react";
import { numberWithCommas } from "../../../utils/number";
import "./index.scss";

const DoctorList = ({ hospitalData, data = [], isSingle }) => {
  if (!isSingle) {
    return (
      <ul className="doctor_list">
        {data.slice(0, 3).map((v, i) => {
          return (
            <li key={i} onClick={() => (window.location.href = `babi://search_doctors/${v.id}`)}>
              <div
                className={`doctor_img ${!v.profileVideo ? "none" : ""}`}
                style={{ background: `url(${v.profileImage})` }}
              ></div>
              <div className="doctor_info">
                <p className="name">
                  {v.name} {v.position} {v.specialist && <span>전문의</span>}
                </p>
                <p className="desc">{hospitalData.name}</p>
                <p className="major">{v.subjects.toString()}</p>
                <p className="count">
                  조회 {numberWithCommas(v.viewCount)} 성형후기 {numberWithCommas(v.reviewCount)}
                </p>
                <div className="badges">
                  {v.eventAskId && <span className="ask" />}
                  {v.videoCount > 0 && <span className="one_min" />}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <div className="doctor_profile">
        <div className="doctor_img none" style={{ background: `url(${data.profile_photo})` }}></div>
        <div className="doctor_info">
          <p className="name">
            {data.name} {data.position}
          </p>
          {data.specialties && <p className="desc">{data.specialties} 전문의</p>}
          <p className="desc">경력 {new Date().getFullYear() - Number(data.since.slice(0, 4)) + 1}년</p>
          <p className="count">조회 {numberWithCommas(data.review_count)}</p>
        </div>
      </div>
    );
  }
};

export default DoctorList;
