import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import gsap, { TweenLite } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { _get_category, _get_review_list, _get_review_list_more } from "../../actions/review";
import Select from "react-select";
import Layout from "../../component/common/layout";
import { _openImageModal, _openModal } from "../../actions/modal";
import useHospital from "../../hooks/useHospital";
import { useEventListener } from "../../utils/use_event_listener";
import { numberWithCommas } from "../../utils/number";
import { getParams } from "../../utils/getParams";

gsap.registerPlugin(ScrollToPlugin);
const options = [
  { value: "popular", label: "인기순" },
  { value: "recent", label: "최신순" },
];

const Review = ({ history, match }) => {
  const hid = match.params.hid;
  const doctorId = getParams("doctor_id");

  const dispatch = useDispatch();
  const hospital = useHospital(hid).data;
  const categoryList = useSelector(state => state.review_reducer.categoryList);
  const reviews = useSelector(state => state.review_reducer.data);
  const pagination = useSelector(state => state.review_reducer.pagination);
  const reveiwsLoading = useSelector(state => state.review_reducer.loading);

  const [cookies, removeCookie] = useCookies(["cookie-name"]);
  const [doctorDetail, setDoctorDetail] = useState(null);
  const [category, setCategory] = useState(useSelector(state => state.review_reducer.category) || null);
  const [sort, setSort] = useState(useSelector(state => state.review_reducer.sort) || options[0]);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const categoryRef = useRef(null);

  const logout = useCallback(() => {
    // 로그아웃시 초기화 되어야하는 url params
    const url = new URL(window.location);
    url.searchParams.delete("doctor_id");

    removeCookie("BABI_TOKEN", { path: "/" });
    history.push(`/hospital/${hid}${url.search}`);
  }, [hid, history, removeCookie]);

  const setOnlySort = n => {
    setCategory(null);
    setSort(options[n]);
  };

  const getMoreReview = useCallback(() => {
    if (!pagination?.links.next) return;
    if (isLoading) return;

    setIsLoading(true);
    dispatch(_get_review_list_more(pagination.links.next, category, sort, cookies["BABI_TOKEN"], logout));
  }, [pagination, isLoading, dispatch, category, sort, cookies, logout]);

  const getDoctorDetail = useCallback(() => {
    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/doctors/${doctorId}`)
      .then(function (response) {
        setDoctorDetail(response.data.data);
      })
      .catch(function (error) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: error.response.data.message,
          })
        );
      });
  }, [dispatch, doctorId]);

  const getTitle = () => {
    if (doctorId && doctorDetail) return `${doctorDetail?.name} ${doctorDetail?.position}`;
    if (!doctorId) return hospital?.name || "성형후기";
  };

  const handleScroll = e => {
    let offset = (document.body.scrollHeight - document.body.clientHeight) * 0.8;
    if (offset < window.scrollY && !isLoading) {
      getMoreReview();
    }
  };

  useEffect(() => {
    setIsLoading(reveiwsLoading);
  }, [reveiwsLoading]);

  useEffect(() => {
    if (!hospital) return;
    if (doctorId) getDoctorDetail();
  }, [hospital, doctorId, getDoctorDetail]);

  useEffect(() => {
    if (!categoryList) dispatch(_get_category());
  }, [categoryList, dispatch]);

  useEffect(() => {
    if (!hospital) return;
    if (history.action === "PUSH") window.scroll(0, 0);
    if (doctorId) dispatch(_get_review_list("doctors", doctorId, category, sort, cookies["BABI_TOKEN"], logout));
    else dispatch(_get_review_list("hospitals", hospital.id, category, sort, cookies["BABI_TOKEN"], logout));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, category, hospital]);

  useEffect(() => {
    if (!categoryRef) return;
    let i = categoryList && categoryList.findIndex(v => v.id === category);
    let childrenPosition =
      categoryRef.current.children[i] &&
      categoryRef.current.children[i].offsetLeft + categoryRef.current.children[i].clientWidth;

    TweenLite.to(categoryRef.current, 0.5, {
      scrollTo: {
        x: childrenPosition || 0,
      },
    });
  }, [category, categoryList]);

  useEventListener("scroll", handleScroll, window);

  return (
    <>
      <Layout title={getTitle()} containerClassName="review" Login>
        <div className="wrap_category">
          <div className="category">
            <ul className="scroll_hide" ref={categoryRef}>
              <li
                className={!category && sort === options[0] ? "select" : ""}
                onClick={() => {
                  setShowSubCategory(false);
                  setOnlySort(0);
                }}
              >
                인기
              </li>
              <li
                className={!category && sort === options[1] ? "select" : ""}
                onClick={() => {
                  setShowSubCategory(false);
                  setOnlySort(1);
                }}
              >
                최신
              </li>
              {categoryList &&
                categoryList.map(v => (
                  <li
                    key={v.id}
                    className={category === v.id ? "select" : ""}
                    onClick={() => {
                      setShowSubCategory(false);
                      setSort(options[0]);
                      setCategory(v.id);
                    }}
                  >
                    {v.name}
                  </li>
                ))}
            </ul>
            <button
              className={`btn_show_category ${showSubCategory ? "active" : ""}`}
              onClick={() => setShowSubCategory(!showSubCategory)}
            ></button>
          </div>
          {showSubCategory && (
            <div className="sub_category">
              <ul>
                <li
                  className={!category && sort === options[0] ? "select" : ""}
                  onClick={() => {
                    setShowSubCategory(false);
                    setOnlySort(0);
                  }}
                >
                  인기
                </li>
                <li
                  className={!category && sort === options[1] ? "select" : ""}
                  onClick={() => {
                    setShowSubCategory(false);
                    setOnlySort(1);
                  }}
                >
                  최신
                </li>
                {categoryList &&
                  categoryList.map(v => (
                    <li
                      key={v.id}
                      className={category === v.id ? "select" : ""}
                      onClick={() => {
                        setShowSubCategory(false);
                        setSort(options[0]);
                        setCategory(v.id);
                      }}
                    >
                      {v.name}
                    </li>
                  ))}
              </ul>
            </div>
          )}
          <div className="sort">
            {category && (
              <Select
                className="react-select-container"
                onChange={v => setSort(v)}
                options={options}
                value={sort}
                isSearchable={false}
                classNamePrefix
              />
            )}
            <span>총 {numberWithCommas((pagination && pagination.total) || 0)}건</span>
          </div>
        </div>

        <div className="wrap_review_list">
          {reviews &&
            reviews.map((v, i) => {
              return (
                <div key={v.id}>
                  <div className="user">
                    <div className="img" style={{ background: `url(${v.user.data.small_image})` }} />
                    <div className="info">
                      <p className="name">{v.user.data.name}</p>
                      <p className="create_time">{v.created_at}</p>
                    </div>
                  </div>
                  <div
                    className={`img_info grid_${v.images.data.length < 7 ? v.images.data.length : "6 more"}`}
                    onClick={e => {
                      history.push(`${window.location.pathname}${window.location.search}`);
                      dispatch(
                        _openImageModal({
                          isReview: true,
                          index: [...e.target.parentElement.childNodes].indexOf(e.target),
                          images: v.images.data,
                        })
                      );
                    }}
                  >
                    {v.images.data.map((v, i) => (
                      <div
                        key={i}
                        className={`img ${v.surgery ? "after" : "before"}`}
                        style={{ background: `url(${v.small_image})` }}
                      />
                    ))}
                  </div>
                  <div className="text_info">
                    <p className="subcategories">{v.subcategories.toString()}</p>
                    <p className="surgery_at">{v.surgery_time && `수술/시술시기 ${v.surgery_time}`}</p>
                    <p className="desc">{v.text}</p>
                    <Link to={`/hospital/${hid}/review/detail/${v.id}${history.location.search}`}>더보기</Link>
                  </div>
                </div>
              );
            })}
          {reviews && reviews.length === 0 && (
            <div className="null">
              <p>조건에 맞는 성형후기가 없습니다</p>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Review;
