import { useDispatch } from "react-redux";
import { _openModal, _closeModal } from "../actions/modal";
import { useEventListener } from "../utils/use_event_listener";

const useOrientationChange = () => {
  const dispatch = useDispatch();

  const handleChange = e => {
    if (window.orientation && window.orientation !== 0) {
      dispatch(
        _openModal({
          type: "alert",
          title: "안내",
          msg: `해당 서비스는 세로 모드에 최적화되어 있습니다. \n세로 모드에서 확인 부탁드립니다.`,
        })
      );
    } else {
      dispatch(_closeModal());
    }
  };

  useEventListener("orientationchange", handleChange, window);
};

export default useOrientationChange;
