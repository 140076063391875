import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { _openModal } from "actions/modal";
import ButtonSrc from "assets/images/icon/ic-question.svg";

const BlockDescButton = styled.button`
  width: 24px;
  height: 24px;
  background: url(${ButtonSrc});
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
`;

const Block = ({ data, ...props }) => {
  const dispatch = useDispatch();

  return (
    <div className="block" {...props}>
      <div className="desc">
        <div className="count">
          <p>최근 3개월간 병원에 의해 게시중단된 게시물</p>
          <p>{data.defamationCount ? `${data.defamationCount.toLocaleString()}건` : "없음"}</p>
        </div>
        <BlockDescButton
          onClick={() => {
            dispatch(
              _openModal({
                type: "alert",
                title: "안내",
                msg: (
                  <>
                    본 정보는 회원분들께 균형있는 정보를 제공하기 위해 도입되었습니다. 최근 90일 동안 해당 병원의
                    요청으로 게시중단된 게시물 수가 표시되며, 진위여부 확인에 따라 표시된 숫자는 변경될 수 있습니다.
                    병원정보 파악시 참고 부탁드립니다
                    <br />
                    <br />
                    *바비톡은 정보통신망 이용촉진 및 정보보호 법률 제 44조 2의(정보 삭제요청)법령을 준수하기 위해,
                    게시중단 요청이 접수되면 불가피하게 게시중단 조치를 할 수 밖에 없습니다.
                  </>
                ),
              })
            );
          }}
        />
      </div>
      {Boolean(data.defamationCount) && (
        <div className="detail_count">
          <p>
            게시중단한 병원 순위<span>{data.defamationRank}위</span>
          </p>
          <p>
            사용자 게시물 복원<span>{data.restoreCount}건</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default Block;
