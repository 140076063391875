import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import "./assets/css/reset.css";
import Fade from "@mui/material/Fade";
import App from "./App";
import store from "./store";
import * as serviceWorker from "./serviceWorker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

ReactDOM.render(
  <Provider store={store()}>
    <QueryClientProvider client={queryClient}>
      <CookiesProvider>
        <SnackbarProvider TransitionComponent={Fade} maxSnack={3} autoHideDuration={3000} preventDuplicate>
          <App />
        </SnackbarProvider>
      </CookiesProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
