import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { _openImageModal } from "actions/modal";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }
`;

const CurrentIndex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 12px;

  > div {
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.7);
  }
  span {
    color: #ffffff;
  }
`;

const MainSlider = ({ data = [] }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [index, setIndex] = useState(0);
  const swiperRef = useRef(null);

  const swiperConfig = {
    arrows: false,
    initialSlide: index,
    infinite: true,
    speed: 300,
    afterChange: i => {
      setIndex(i);
    },
  };

  if (!data.length) return null;
  return (
    <Wrapper
      onClick={e => {
        history.push(`${window.location.pathname}${window.location.search}`);
        dispatch(
          _openImageModal({
            index,
            images: data,
          })
        );
      }}
    >
      <Slider ref={swiperRef} {...swiperConfig}>
        {data.map((v, i) => (
          <img key={i} src={v.image || v} lky={i} alt={`병원 이미지 ${i + 1}`} />
        ))}
      </Slider>
      <CurrentIndex>
        <div>
          <span>{index + 1}</span> / {data.length}
        </div>
      </CurrentIndex>
    </Wrapper>
  );
};

export default MainSlider;
