import { forwardRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import useHospital from "hooks/useHospital";
import { useUser } from "hooks/useUser";
import { user, place } from "api/hospital";
import { ReactComponent as Back } from "assets/images/icon/ic-back.svg";
import { ReactComponent as Share } from "assets/images/icon/ic-share.svg";
import { ReactComponent as BookmarkIcon } from "assets/images/icon/ic-bookmark.svg";
import { ReactComponent as BookmarkActiveIcon } from "assets/images/icon/ic-bookmark-active.svg";

const BookMark = () => {
  const match = useRouteMatch();
  const { data: hospital } = useHospital(match.params.hid);
  const { enqueueSnackbar } = useSnackbar();
  const postLike = useMutation(async hid => await place.postLike(hid));
  const deleteLike = useMutation(async hid => await place.deleteLike(hid));
  const [like, setLike] = useState(hospital?.like ?? false);
  const { token } = useUser();

  const onClick = async () => {
    const checkPassResp = token && (await user.checkPass());

    if (!token || checkPassResp?.data?.passCheck) {
      like
        ? deleteLike.mutate(match.params.hid, {
            onSuccess: () => setLike(like => !like),
            onError: e => {
              enqueueSnackbar(e.response?.data?.message ?? e.message, {
                variant: "error",
              });
            },
          })
        : postLike.mutate(match.params.hid, {
            onSuccess: () => setLike(like => !like),
            onError: e => {
              enqueueSnackbar(e.response?.data?.message ?? e.message, {
                variant: "error",
              });
            },
          });
    } else {
      window.location.href = "babi://pass?type=verfiy";
    }
  };

  if (like) return <BookmarkActiveIcon className="bookmark" onClick={() => onClick()} />;
  else return <BookmarkIcon className="bookmark" onClick={() => onClick()} />;
};

// ios/aos
export const checkMobilePlatform = () => {
  let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
  if (varUA.indexOf("iphone") > -1 || varUA.indexOf("ipad") > -1 || varUA.indexOf("ipod") > -1) {
    return "ios";
  }
  if (varUA.indexOf("android") > -1) {
    return "aos";
  } else {
    return "other";
  }
};

export const appClose = () => {
  const platform = checkMobilePlatform();
  if (platform === "ios") {
    window.location.href = `scriptHandler://closeBrowser`;
  }
  if (platform === "aos") {
    window.scriptHandler && window.scriptHandler.closeBrowser();
  }
};

const Header = forwardRef(({ loading, title, data }, ref) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const notInBabitalk = window.location.pathname.includes("notInBabitalk");
  const back = queryParameters.get("back");

  const onClickBack = () => {
    if (back === "close") {
      window.parent.postMessage({ message: "click_back" }, "*");
    } else {
      appClose();
    }
  };

  if (loading)
    return (
      <header className="hospital_header">
        <Back className="back" onClick={onClickBack} />
      </header>
    );
  return (
    <header className="hospital_header" ref={ref}>
      <Back className="back" onClick={onClickBack} />
      <span className="name">{title}</span>
      <div className="right">
        <Share className="share" onClick={() => (window.location.href = `babi://hospitals/${data?.id}/share`)} />
        {!notInBabitalk && <BookMark data={data} />}
      </div>
    </header>
  );
});

export default Header;
