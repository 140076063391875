import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Login from "pages/hospital/Login";
import Join from "pages/hospital/Join";
import Hospital from "pages/hospital/Hospital";
import TemporaryHospital from "pages/hospital/TemporaryHospital";
import Review from "pages/hospital/Review";
import ReviewDetail from "pages/hospital/ReviewDetail";
import Event from "pages/hospital/Event";
import Doctor from "pages/hospital/Doctor";
import Youtube from "pages/hospital/Youtube";

import CommonEvent from "component/common/commonEvent";

import "assets/css/hospital/common.scss";
import "assets/css/hospital/pages/hospital.scss";
import "assets/css/hospital/pages/review.scss";
import "assets/css/hospital/pages/login.scss";

const HospitalRouter = () => {
  useEffect(() => {
    document.body.parentElement.style.height = "100%";
    document.body.style.height = "100%";
    document.getElementById("root").style.height = "100%";
  }, []);

  return (
    <div className="hospital-wrap">
      <Switch>
        <Route exact path="/hospital/:hid/login" component={Login} />
        <Route exact path="/hospital/:hid/join" component={Join} />
        <Route exact path="/hospital/:hid" component={Hospital} />
        <Route exact path="/hospital/notInBabitalk/:hid" component={TemporaryHospital} />
        <Route exact path="/hospital/:hid/review" component={Review} />
        <Route exact path="/hospital/:hid/review/detail/:id" component={ReviewDetail} />
        <Route path="/hospital/:hid/event" component={Event} />
        <Route path="/hospital/:hid/doctor" component={Doctor} />
        <Route path="/hospital/:hid/youtube" component={Youtube} />
      </Switch>

      {/* 모든 페이지 공통 이벤트 */}
      <CommonEvent />
    </div>
  );
};

export default HospitalRouter;
