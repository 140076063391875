import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { _openModal } from "actions/modal";
import { useQuery } from "@tanstack/react-query";

const useHospital = hid => {
  const notInBabitalk = window.location.pathname.includes("notInBabitalk");
  const dispatch = useDispatch();
  const hospital = useSelector(state => state.hospital_reducer);
  const queryParameters = new URLSearchParams(window.location.search);
  const token = queryParameters.get("token");

  const { refetch, isLoading } = useQuery(
    ["fetchData", hid],
    () =>
      hid &&
      axios.get(
        process.env.REACT_APP_TESTING_API_URL +
          (notInBabitalk ? `/place/notInBabitalkDetail/${hid}` : `/place/hospital/${hid}`),
        {
          headers: token && {
            Authorization: `Bearer ${token}`,
          },
        }
      ),
    {
      enabled: hospital?.hid !== hid,
      onSuccess: resp => {
        dispatch({
          type: "HOSPITAL_LOAD_SUCCESS",
          hid: hid,
          data: resp.data,
        });
      },
      onError: e => {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: e.response.data.description,
          })
        );
      },
    }
  );
  return { ...hospital, refetch, isLoading };
};

export default useHospital;
