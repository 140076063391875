import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

const Wrapper = styled.div`
  position: relative;
  background: #fff;
  border-radius: 16px 16px 0px 0px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #484760;
    text-align: left;
  }
`;

const SelectBox = styled.div`
  border: 1px solid #dadadf;
  border-radius: 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #7e7e8f;
  margin-top: 16px;
  overflow: hidden;
  button {
    width: 100%;
    height: 51px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    &:not(:last-child) {
      border-bottom: 1px solid #dadadf;
    }
    svg {
      margin-left: auto;
    }
  }
`;

const SelectList = styled.div`
  overflow: scroll;
  max-height: 202px;
`;

const SaveButton = styled.button`
  width: 100%;
  height: 48px;
  background: #897dff;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 32px;
  transition: 0.2s;
  &:disabled {
    background: #adaebc;
  }
`;

const DoctorSelectButton = ({ doctor }) => {
  const [value, setValue] = useState(null);
  const [openSelectList, setOpenSelectList] = useState(false);
  const [openSelectDoctorModal, setOpenSelectDoctorModal] = useState(false);

  useEffect(() => {
    if (openSelectDoctorModal) {
      setValue(null);
      setOpenSelectList(false);
    }
  }, [openSelectDoctorModal]);

  return (
    <>
      <Drawer
        anchor="bottom"
        open={openSelectDoctorModal}
        onClose={() => setOpenSelectDoctorModal(false)}
        sx={{ "& .MuiPaper-root": { borderRadius: "16px 16px 0px 0px" } }}
      >
        <Wrapper>
          <CloseRoundedIcon
            sx={{ color: "#7E7E8F", marginLeft: "auto" }}
            onClick={() => setOpenSelectDoctorModal(false)}
          />
          <h1>
            상담하고 싶은 의사를
            <br />
            선택해주세요
          </h1>
          <SelectBox>
            <button onClick={() => setOpenSelectList(v => !v)}>
              {value?.name ?? "선택"}
              {openSelectList ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </button>
            {openSelectList && (
              <SelectList>
                {doctor.map((v, i) => (
                  <button
                    key={i}
                    onClick={() => {
                      setValue(v);
                      setOpenSelectList(false);
                    }}
                  >
                    {v.name}
                  </button>
                ))}
              </SelectList>
            )}
          </SelectBox>
          <SaveButton
            disabled={!value}
            onClick={() => (window.location.href = `babi://doctor_events/${value.eventAskId}/ask/breakaway`)}
          >
            상담 신청하기
          </SaveButton>
        </Wrapper>
      </Drawer>
      <button
        onClick={() => {
          setOpenSelectDoctorModal(true);
        }}
      >
        의사 지정 상담 신청
      </button>
    </>
  );
};

export default DoctorSelectButton;
