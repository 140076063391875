import React from "react";
import StarRateRoundedIcon from "@mui/icons-material/StarRateRounded";
import { ReactComponent as GoodIcon } from "assets/images/icon/badge_good_event.svg";
import { ReactComponent as PrepaymentIcon } from "assets/images/icon/badge-prepayment.svg";
import { ReactComponent as PrepaymentVatIcon } from "assets/images/icon/badge-prepayment_vat.svg";

import "./index.scss";

const EventList = ({ hospitalData, data = [], prepayment }) => {
  return (
    <ul className="event_list">
      {data.map((v, i) => {
        return (
          <li key={i} onClick={() => (window.location.href = `babi://events/${v.id}`)}>
            <div className={`event_thumb ${v.is_hot ? "hot" : ""}`} style={{ background: `url(${v.image})` }}></div>
            <div className="event_info">
              <p className="name">{v.name}</p>
              <p className="desc">{v.description}</p>
              <p className="client">
                {hospitalData.locationName} • {hospitalData.name}
              </p>
              <p className="price">
                <span className="discount_price">{v.price.toLocaleString()}원</span>
                {Boolean(v.price < v.originalPrice) && (
                  <>
                    <span className="percentage">{Number.parseInt(100 - (v.price / v.originalPrice) * 100)}%</span>
                    <span>{v.originalPrice.toLocaleString()}</span>
                  </>
                )}
              </p>
              {Boolean(v.reviewCount) && (
                <>
                  <p className="card">(6개월 무이자 할부시 월 {Number((v.price / 6).toFixed(0)).toLocaleString()}원)</p>
                  <p className="review_score">
                    <span>
                      <StarRateRoundedIcon />
                      {v.reviewScore} ({v.reviewCount})
                    </span>
                    <GoodIcon />
                  </p>
                  <p className="short_cut">
                    <PrepaymentIcon />
                    <PrepaymentVatIcon />
                  </p>
                </>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default EventList;
