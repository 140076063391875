import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { _reset_review_list } from "actions/review";

import useHospital from "hooks/useHospital";
import { useSnackbar } from "notistack";
import { useEventListener } from "utils/use_event_listener";
import { numberWithCommas } from "utils/number";
import FaceThinkingIcon from "assets/images/icon/face_thinking.svg";

import Header from "component/common/layout/Header";
import Block from "component/hospital/detail/Block";
import DoctorSelectButton from "component/hospital/detail/DoctorSelectButton";
import Banner from "component/hospital/detail/Banner";
import MainSlider from "component/hospital/detail/MainSlider";
import EventList from "component/hospital/eventList";
import DoctorList from "component/hospital/doctorList";
import YoutubeList from "component/hospital/youtubeList";

const convertDayName = dayName => {
  switch (dayName) {
    case "monday":
      return "월";
    case "tuesday":
      return "화";
    case "wednesday":
      return "수";
    case "thursday":
      return "목";
    case "friday":
      return "금";
    case "saturday":
      return "토";
    case "sunday":
      return "일";

    default:
      break;
  }
};

const mergeSameTime = arr => {
  return arr.reduce((acc, current) => {
    const x = acc.find(item => item.dayFrom === current.dayFrom && item.dayTo === current.dayTo);
    if (!x) {
      const newCurr = {
        dayFrom: current.dayFrom,
        dayTo: current.dayTo,
        dayName: [current.dayName],
      };
      return acc.concat([newCurr]);
    } else {
      const currData = x.dayName.filter(d => d === current.dayName);
      if (!currData.length) {
        x.dayName.push(current.dayName);
        return acc;
      } else {
        return acc;
      }
    }
  }, []);
};

const Hospital = ({ match }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["cookie-name"]);
  const { data: hospital } = useHospital(match.params.hid);
  const { enqueueSnackbar } = useSnackbar();

  const headerRef = useRef(null);
  const mainImgRef = useRef(null);

  useEffect(() => {
    dispatch(_reset_review_list());
  }, [dispatch]);

  const handleScroll = e => {
    if (!mainImgRef.current.clientHeight) return;

    if (mainImgRef.current.clientHeight < window.scrollY) {
      if (headerRef.current.className !== "") {
        headerRef.current.className = "";
        window.webkit.messageHandlers?.updateStatusBar?.postMessage("default");
      }
    } else {
      if (headerRef.current.className !== "hospital_header") {
        headerRef.current.className = "hospital_header";
        window.webkit.messageHandlers?.updateStatusBar?.postMessage("light");
      }
    }
  };

  useEventListener("scroll", handleScroll, window);

  if (!hospital || hospital.isLoading) return <Header loading />;
  return (
    <div>
      <Header title={hospital.name} data={hospital} ref={headerRef} />
      {Boolean(hospital.images.length) && (
        <section className="profile_img" ref={mainImgRef}>
          <MainSlider data={hospital.images} />
        </section>
      )}

      <section className="profile" style={!hospital.images.length ? { marginTop: "54px" } : {}}>
        <div className="title">
          <h1>{hospital.name}</h1>
          <p>
            {[
              hospital?.medicalDepartment,
              hospital?.locationName,
              hospital?.viewCount > 9999
                ? `조회 ${(hospital.viewCount / 10000).toFixed(1)}만`
                : `조회 ${hospital.viewCount.toLocaleString()}`,
            ]
              .filter(v => v)
              .map((v, i) => `${i ? ` • ` : ""}${v}`)}
          </p>
        </div>
        {Boolean([...(hospital?.majorCategories ?? []), ...(hospital?.categories ?? [])].length) && (
          <ul className="tags">
            {(hospital?.majorCategories ?? []).map((v, i) => (
              <li key={i} className="major">
                {v}
              </li>
            ))}
            {(hospital?.categories ?? []).map((v, i) => (
              <li key={i}>{v}</li>
            ))}
          </ul>
        )}

        {Boolean(hospital.hospitalInfo.length) && (
          <ul className="confirms scroll_hide">
            {hospital.hospitalInfo.map((v, i) => {
              switch (v.type) {
                case "cctv":
                  return (
                    <li key={i} className="confirm_01">
                      <p>CCTV설치</p>
                    </li>
                  );
                case "specialist":
                  return (
                    <li key={i} className="confirm_02">
                      <p>전문의{v.data}명</p>
                    </li>
                  );
                case "anes":
                  return (
                    <li key={i} className="confirm_03">
                      <p>마취전문의</p>
                    </li>
                  );
                case "recovery_room":
                  return (
                    <li key={i} className="confirm_04">
                      <p>전담회복실</p>
                    </li>
                  );
                case "female":
                  return (
                    <li key={i} className="confirm_05">
                      <p>여의사진료</p>
                    </li>
                  );
                case "night":
                  return (
                    <li key={i} className="confirm_06">
                      <p>야간상담</p>
                    </li>
                  );
                default:
                  return false;
              }
            })}
          </ul>
        )}
      </section>

      {hospital.description && (
        <section className="introduce">
          <h2>병원소개</h2>
          {Boolean((hospital?.naverKeywords ?? []).length) && (
            <ul className="tags">
              {(hospital?.naverKeywords ?? []).map((v, i) => (
                <li key={i}>#{v}</li>
              ))}
            </ul>
          )}
          <p>{hospital.description}</p>
        </section>
      )}

      {hospital.reviewCount > 0 && (
        <section className="review">
          <h2>
            성형후기
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                window.location.href = `babi://hospitals/${hospital.id}/reviews`;
              }}
            >
              모두보기 ({numberWithCommas(hospital.reviewCount)})
            </a>
          </h2>
          <ul className="img_list scroll_hide" onClick={e => cookies["BABI_TOKEN"]}>
            {hospital.reviews.map((v, i) => (
              <li
                key={i}
                className={v.blindImage ? "blur" : ""}
                onClick={() => {
                  window.location.href = `babi://reviews/${v.id}`;
                }}
              >
                <div style={{ background: `url(${v.image})` }} />
              </li>
            ))}
          </ul>
        </section>
      )}

      <section>
        <h2>
          게시중단
          {/* eslint-disable-next-line */}
          <a
            onClick={() => {
              window.location.href = `babi://hospitals/${hospital.id}/defamation`;
            }}
          >
            더보기
          </a>
        </h2>
        <Block data={hospital.defamationData} style={{ marginTop: 8 }} />
      </section>

      {hospital.prepaymentEventCount > 0 && hospital.prepaymentEvents.length > 0 && (
        <section className="event">
          <h2>
            미리결제 이벤트
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                window.location.href = `babi://hospitals/${hospital.id}/prepayment_events`;
              }}
            >
              모두보기 ({numberWithCommas(hospital.prepaymentEventCount)})
            </a>
          </h2>
          <EventList hospitalData={hospital} data={hospital.prepaymentEvents} prepayment />
        </section>
      )}

      {hospital.eventCount > 0 && hospital.events.length > 0 && (
        <section className="event">
          <h2>
            이벤트
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                window.location.href = `babi://hospitals/${hospital.id}/events`;
              }}
            >
              모두보기 ({numberWithCommas(hospital.eventCount)})
            </a>
          </h2>
          <EventList hospitalData={hospital} data={hospital.events} />
        </section>
      )}

      {hospital.doctorCount > 0 && (
        <section className="doctor">
          <h2>
            의료진 정보
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                window.location.href = `babi://hospitals/${hospital.id}/doctors`;
              }}
            >
              모두보기 ({numberWithCommas(hospital.doctorCount)})
            </a>
          </h2>
          <DoctorList hospitalData={hospital} data={hospital.doctors} />
        </section>
      )}

      {hospital.youtubeVideoCount > 0 && (
        <section className="youtube">
          <h2>
            공식 유튜브
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              onClick={() => {
                window.location.href = `babi://hospitals/${hospital.id}/youtubes`;
              }}
            >
              모두보기 ({numberWithCommas(hospital.youtubeVideoCount)})
            </a>
          </h2>
          <YoutubeList data={[{ title: hospital.youtubeTitle, thumbnails: hospital.youtubeThumbnail }]} />
        </section>
      )}

      <section className="info">
        <h2>병원정보</h2>
        {hospital.operationTime && (
          <dl>
            <dt>진료시간</dt>
            <dd className="biz_hours">
              <div>
                {mergeSameTime({ ...hospital }.operationTime.filter(v => v.dayNight)).map((v, i) => (
                  <p key={i}>
                    <span style={{ opacity: i === 0 ? 1 : 0 }}>야간진료</span>{" "}
                    {v.dayName.map(v => convertDayName(v)).join(", ")} {v.dayFrom}~{v.dayTo}
                  </p>
                ))}
                {mergeSameTime(
                  { ...hospital }.operationTime.filter(v => v.dayType === "WEEKEND" && v.dayFrom && v.dayTo)
                ).map((v, i) => (
                  <p key={i}>
                    <span style={{ opacity: i === 0 ? 1 : 0 }}>휴일진료</span>{" "}
                    {v.dayName.map(v => convertDayName(v)).join(", ")} {v.dayFrom}~{v.dayTo}
                  </p>
                ))}
                {hospital.operationTime
                  .filter(v => v.dayType === "WEEKDAY")
                  .map((v, i) => (
                    <p key={i}>
                      {convertDayName(v.dayName)} {v.dayOff ? "휴무" : `${v.dayFrom}~${v.dayTo}`}
                    </p>
                  ))}
                {hospital.operationTime
                  .filter(v => v.dayType === "LAUNCH_TIME" && v.dayFrom && v.dayTo)
                  .map((v, i) => (
                    <p key={i}>
                      점심시간 {v.dayFrom}~{v.dayTo}
                    </p>
                  ))}
              </div>
            </dd>
          </dl>
        )}
        {hospital.address && (
          <dl className="address">
            <dt>병원주소</dt>
            <dd>
              <div>{hospital.address}</div>
              <div>
                <button
                  className="btn-outline"
                  onClick={() => {
                    if (window !== window.parent) {
                      window.parent.postMessage({ message: "copy_address", address: hospital.address }, "*");
                      enqueueSnackbar("복사되었습니다");
                    } else {
                      window.navigator.clipboard
                        .writeText(hospital.address)
                        .then(() => enqueueSnackbar("복사되었습니다"));
                    }
                  }}
                >
                  주소복사
                </button>
                <button
                  className="btn-outline"
                  onClick={() => {
                    const url = `https://m.map.naver.com/search2/search.naver?query=${encodeURIComponent(
                      hospital.address
                    )}`;
                    if (window !== window.parent) {
                      window.parent.postMessage({ message: "click_address_map", url }, "*");
                    } else {
                      window.open(url, "_blank");
                    }
                  }}
                >
                  지도보기
                </button>
              </div>
            </dd>
          </dl>
        )}
        {hospital.way && (
          <dl>
            <dt>오시는길</dt>
            <dd>
              <div>{hospital.way}</div>
            </dd>
          </dl>
        )}
        <dl>
          <dt>주차여부</dt>
          <dd>{hospital.parkingLot ? "가능" : "불가능"}</dd>
        </dl>
        <Banner
          icon={FaceThinkingIcon}
          text={["알고계신 정보가 다른 정보가 있나요?", "정보 수정 요청하기"]}
          onClick={() => {
            window.location = `mailto:cs@babitalk.com?Subject=${hospital.name} 정보 수정 요청 및 문의 드립니다.`;
          }}
        />
      </section>
      <div className="fix_bottom">
        {hospital.realtalkChannelId && (
          <div
            className="float_chat"
            onClick={() => {
              window.location.href = `babi://realtalk/hospitals/${hospital.id}/realtalk_channel/open_channel_hospital_${hospital.id}`;
            }}
          >
            유저 채팅방
          </div>
        )}
        {hospital.eventAskId && (
          <button
            onClick={() => {
              window.location.href = `babi://hospital_events/${hospital.eventAskId}/ask/breakaway`;
            }}
          >
            병원상담신청
          </button>
        )}
        {hospital.doctors.find(v => v.eventAskId) && (
          <DoctorSelectButton doctor={hospital.doctors.filter(v => v.eventAskId)} />
        )}
      </div>
    </div>
  );
};

export default Hospital;
