import React, { useEffect, useRef } from "react";
import useHospital from "hooks/useHospital";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import BabiTalkLogo from "assets/images/logo/babitalk.svg";
import Header from "component/common/layout/Header";
import MainSlider from "component/hospital/detail/MainSlider";
import Banner from "component/hospital/detail/Banner";

import { useEventListener } from "../../utils/use_event_listener";
dayjs.locale("ko");

const TemporaryHospital = ({ match }) => {
  const { data: hospital } = useHospital(match.params.hid);
  const { enqueueSnackbar } = useSnackbar();
  const headerRef = useRef(null);
  const mainImgRef = useRef(null);

  const handleScroll = e => {
    if (mainImgRef.current.clientHeight < window.scrollY) {
      if (headerRef.current.className !== "") {
        headerRef.current.className = "";
        window.webkit.messageHandlers?.updateStatusBar?.postMessage("default");
      }
    } else {
      if (headerRef.current.className !== "hospital_header") {
        headerRef.current.className = "hospital_header";
        window.webkit.messageHandlers?.updateStatusBar?.postMessage("light");
      }
    }
  };

  useEffect(() => {
    if (!headerRef.current) return;
    if ((hospital?.images ?? []).length === 0) headerRef.current.className = "";
  }, [hospital?.images, headerRef]);

  useEventListener("scroll", handleScroll, window);

  if (!hospital || hospital.isLoading) return <Header loading />;
  return (
    <div>
      <Header title={hospital.name} data={hospital} ref={headerRef} />
      {Boolean(hospital.images.length) && (
        <section className="profile_img" ref={mainImgRef}>
          <MainSlider data={hospital.images} />
        </section>
      )}
      <section className="profile" style={!hospital.images.length ? { marginTop: "54px" } : {}}>
        <div className="title">
          <h1>{hospital.name}</h1>
          <p>{hospital.subject}</p>
        </div>
      </section>

      {hospital.description && (
        <section className="introduce">
          <h2>병원소개</h2>
          {Boolean((hospital?.keywords ?? []).length) && (
            <ul className="tags">
              {(hospital?.keywords ?? []).map((v, i) => (
                <li key={i}>#{v}</li>
              ))}
            </ul>
          )}
          <p>{hospital.description}</p>
        </section>
      )}

      <section className="info">
        <h2>병원정보</h2>
        {hospital.bizHours && (
          <dl>
            <dt>진료시간</dt>
            <dd className="biz_hours">
              <div>
                {hospital.bizHours.map((v, i) => (
                  <p key={i}>
                    {v.name.replace("요일", "")}{" "}
                    {v.isDayOff ? "정기휴무" : `${v.startOn.slice(0, 5)}~${v.endOn.slice(0, 5)}`}
                  </p>
                ))}
              </div>
            </dd>
          </dl>
        )}
        {hospital.address && (
          <dl className="address">
            <dt>병원주소</dt>
            <dd>
              <div>{hospital.address}</div>
              <div>
                <button
                  className="btn-outline"
                  onClick={() => {
                    if (window !== window.parent) {
                      window.parent.postMessage({ message: "copy_address", address: hospital.address }, "*");
                      enqueueSnackbar("복사되었습니다");
                    } else {
                      window.navigator.clipboard
                        .writeText(hospital.address)
                        .then(() => enqueueSnackbar("복사되었습니다"));
                    }
                  }}
                >
                  주소복사
                </button>
                <button
                  className="btn-outline"
                  onClick={() => {
                    const url = `https://m.map.naver.com/search2/search.naver?query=${encodeURIComponent(
                      hospital.address
                    )}`;
                    if (window !== window.parent) {
                      window.parent.postMessage({ message: "click_address_map", url }, "*");
                    } else {
                      window.open(url, "_blank");
                    }
                  }}
                >
                  지도보기
                </button>
              </div>
            </dd>
          </dl>
        )}
        {hospital.way && (
          <dl>
            <dt>오시는길</dt>
            <dd>
              <div>{hospital.way}</div>
            </dd>
          </dl>
        )}
        {hospital.phoneNumber && (
          <dl>
            <dt>전화번호</dt>
            <dd>
              {hospital.phoneNumber}
              <button
                className="btn-outline right"
                onClick={() => {
                  if (window !== window.parent) {
                    window.parent.postMessage({ message: "copy_phone_number", phoneNumber: hospital.phoneNumber }, "*");
                    enqueueSnackbar("복사되었습니다");
                  } else {
                    window.navigator.clipboard
                      .writeText(hospital.phoneNumber)
                      .then(() => enqueueSnackbar("복사되었습니다"));
                  }
                }}
              >
                복사
              </button>
            </dd>
          </dl>
        )}
        {hospital.homepage && (
          <dl>
            <dt>홈페이지</dt>
            <dd>
              {hospital.homepage}
              <button
                className="btn-outline right"
                onClick={() => {
                  if (window !== window.parent) {
                    window.parent.postMessage({ message: "click_homepage", url: hospital.homepage }, "*");
                  } else {
                    window.open(hospital.homepage, "_blank");
                  }
                }}
              >
                이동
              </button>
            </dd>
          </dl>
        )}
        <Banner
          icon={BabiTalkLogo}
          text={["바비톡에 병원을 입점시키고 싶다면?", "우리 병원 입점 요청하기"]}
          onClick={() => {
            const url = "https://www.babitalk.com/join";
            if (window !== window.parent) {
              window.parent.postMessage({ message: "click_homepage", url }, "*");
            } else {
              window.open(url, "_blank");
            }
          }}
        />
      </section>
      <div className="fix_bottom">
        <button
          onClick={() => {
            window.location = `tel:${hospital.phoneNumber}`;
          }}
        >
          전화하기
        </button>
      </div>
    </div>
  );
};

export default TemporaryHospital;
