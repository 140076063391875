import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { _openImageModal, _openModal, _closeModal } from "../../actions/modal";
import Layout from "../../component/common/layout";
import DoctorList from "../../component/hospital/doctorList";
// import OneMinList from "../component/common/oneMinList";
import ReactPlayer from "react-player";
import { numberWithCommas } from "../../utils/number";

const DoctorDetail = ({ match, history }) => {
  const dispatch = useDispatch();
  const hid = match.params.hid;
  const [cookies, ,] = useCookies(["cookie-name"]);
  const [detail, setDetail] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);

  const open = () => {
    dispatch(
      _openModal({
        type: "confirm",
        title: "안내",
        buttonText: ["취소", "로그인"],
        msg: "해당 병원의 성형후기는 바비톡 계정으로 로그인 시 확인이 가능합니다.",
        onAction: () => {
          dispatch(_closeModal());
          history.push(
            `${window.location.pathname.slice(0, window.location.pathname.indexOf("/doctor/detail/"))}/login${
              history.location.search
            }`
          );
        },
      })
    );
  };

  const getDoctorDetail = useCallback(() => {
    axios
      .get(process.env.REACT_APP_HOSPITAL_API_URL + `/doctors/${match.params.id}`)
      .then(function (response) {
        setDetail(response.data.data);
      })
      .catch(function (error) {
        dispatch(
          _openModal({
            type: "alert",
            title: "안내",
            msg: error.response.data.message,
          })
        );
      });
  }, [dispatch, match.params.id]);

  useEffect(() => {
    window.scroll(0, 0);
    getDoctorDetail();
  }, [getDoctorDetail]);

  if (!detail) return false;
  return (
    <Layout title={`${detail.name} ${detail.position}`} containerClassName="full doctor_detail" Login>
      <div className="">
        {detail.profile_video && (
          <div className="video" onClick={() => setPlayVideo(true)}>
            <div
              className="video_thumbnail"
              onClick={() => setPlayVideo(true)}
              style={{ background: `url(${detail.profile_video_thumbnail})` }}
            />
            {playVideo && <ReactPlayer className="react-player" playing url={detail.profile_video} controls />}
          </div>
        )}
        <DoctorList isSingle data={detail} />
      </div>
      <section className="profile">
        <h2>주요진료분야</h2>
        <ul className="categories">
          {detail.subject.map((v, i) => (
            <li key={i}>{v}</li>
          ))}
        </ul>
      </section>
      {detail.review_count > 0 && (
        <section className="review" onClick={() => !cookies["BABI_TOKEN"] && open()}>
          <h2>
            성형후기
            <Link
              to={
                cookies["BABI_TOKEN"] &&
                `/hospital/${hid}/review${history.location.search}${history.location.search ? "&" : "?"}doctor_id=${
                  match.params.id
                }`
              }
            >
              모두보기 ({numberWithCommas(detail.review_count)})
            </Link>
          </h2>
          <ul className={`img_list scroll_hide ${!cookies["BABI_TOKEN"] ? "blur" : ""}`}>
            {detail.reviews.items.data.map((v, i) => (
              <li
                key={i}
                onClick={() =>
                  cookies["BABI_TOKEN"] &&
                  history.push(`/hospital/${hid}/review/detail/${v.id}${history.location.search}`)
                }
              >
                <div style={{ background: `url(${v.small_image})` }} />
              </li>
            ))}
          </ul>
          {!cookies["BABI_TOKEN"] && (
            <p className="blur_text">해당 병원의 성형후기는 바비톡 계정으로 로그인 시 확인이 가능합니다.</p>
          )}
        </section>
      )}
      {/* <section className="one_min">
				<h2>1분 닥터<Link to={`/hospital/one-min-doctor${history.location.search}`}>모두보기 (32)</Link></h2>
				<OneMinList />
			</section> */}
      <section className="career">
        <h2>경력 및 활동</h2>
        <p>{detail.career}</p>
      </section>
      <section>
        <ul
          className="img_list scroll_hide"
          onClick={e => {
            history.push(`${window.location.pathname}${window.location.search}`);
            dispatch(
              _openImageModal({
                index: [...e.target.parentElement.childNodes].indexOf(e.target),
                images: detail.career_images.data.map(v => v.image),
              })
            );
          }}
        >
          {detail.career_images.data.map((v, i) => (
            <li key={i} style={{ background: `url(${v.small_image})` }}></li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export default DoctorDetail;
