import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";

import HospitalRouter from "./route/HospitalRouter";
import ExhibitionRouter from "./route/ExhibitionRouter";
import { useAxios } from "hooks/useAxios";

import Modal from "component/common/modal";
import ImageModal from "component/common/imageModal";
import Loading from "component/common/loading";

function App() {
  const modal_reducer = useSelector(store => store.modal_reducer);
  const image_modal_reducer = useSelector(store => store.image_modal_reducer);
  const loading_reducer = useSelector(store => store.loading_reducer);
  useAxios();

  return (
    <Router>
      <Switch>
        <Route path="/hospital" component={HospitalRouter} />
        <Route path="/special_exhibitions" component={ExhibitionRouter} />
      </Switch>

      {/* 팝업 */}
      {modal_reducer.show && <Modal {...modal_reducer} />}
      {image_modal_reducer.show && <ImageModal {...image_modal_reducer} />}
      {loading_reducer.show && <Loading />}
    </Router>
  );
}

export default App;
