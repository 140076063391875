import { useEffect, useState } from "react";

function getCookie(cName) {
  const name = cName + "=";
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split("; ");
  let res;
  cArr.forEach(val => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
}

export const getUser = () => {
  let babitalk = window?.babitalkApplication ?? {};

  return {
    version: babitalk.appVersion || getCookie("appversion"),
    token: babitalk.accessToken || getCookie("token"),
    userId: babitalk.userId || getCookie("userId"),
  };
};

export const useUser = () => {
  const [version, setVersion] = useState();
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    let info = getUser();
    setVersion(info.version);
    setToken(info.token);
    setUserId(info.userId);
  }, []);

  return { getUser, version, token, userId };
};
