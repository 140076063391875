import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./index.scss";

const Layout = ({ buttons, onAction, Login, title, containerClassName, children }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [cookies, , removeCookie] = useCookies(["cookie-name"]);
  const hid = match.params.hid;

  const logout = () => {
    // 로그아웃시 초기화 되어야하는 url params
    const url = new URL(window.location);
    url.searchParams.delete("doctor_id");

    removeCookie("BABI_TOKEN", { path: "/" });
    if (window.location.pathname.includes("review")) {
      history.push(`/hospital/${hid}${url.search}`);
    }
    window.location.reload();
  };

  return (
    <>
      <header>
        {buttons &&
          buttons.map((v, i) => {
            if (v === "back")
              return (
                <button
                  key={i}
                  className="btn_back"
                  onClick={e => {
                    history.length ? history.push(`/hospital/${hid}`) : history.goBack();
                  }}
                />
              );
            else if (v === "close") return <button key={i} className="btn_close" onClick={e => onAction()} />;
            return false;
          })}
        {title && <p>{title}</p>}
        {Login &&
          (cookies["BABI_TOKEN"] ? (
            <>
              {/* eslint-disable-next-line */}
              <a className="btn_logout" onClick={() => logout()}>
                로그아웃
              </a>
            </>
          ) : (
            <Link to={`/hospital/${hid}/login${history.location.search}`} className="btn_login">
              로그인
            </Link>
          ))}
      </header>
      <div className={`warp_content ${containerClassName || ""}`}>{children}</div>
    </>
  );
};

export default Layout;
